$mediaMaxWidth: 1260px;
$mediaMidWidth: 960px;
$mediaMinWidth: 768px;

#page-content:before {
    content: "";
    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-size: cover;
    z-index: 0;

    background-color: var(--color-black-1);
    filter: blur(5px);
}

#page-content {
    position: relative;

    height: 100%;
    width: 100%;

    color: #bfbfbf;
}

#page-content > span {
    position: relative;
    z-index: 1;

    height: 100%;

    display: flex;
    justify-content: center;
}

/* Page Layout */
#home-page-upper-grid {
    display: flex;
    align-content: center;
}

#home-page-upper-grid > div {
    background-color: var(--color-transparent);
    text-align: left;

    box-shadow: -10px -10px var(--color-black-2);
}

/* Summary */
.intro-title {
    margin: 0;
}

#summary > p {
    font-size: 1.5em;

    margin-bottom: 2%;
}

/* Skills */
#skills-grid {
    display: grid;
}

#skills-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
}

#skills-grid > div {
    display: flex;
    flex-direction: column;

    padding-left: 4%;
    padding-right: 4%;
}

#skills-grid > div h2 {
    text-align: left;
}

#skills-grid > div p {
    text-align: left;
}

#home-page-upper-grid a {
    color: #00FFFF;
}

@media (max-width: $mediaMinWidth) {
    #page-content {
        padding-top: var(--header-height-small);
    }

    #home-page-upper-grid {
        flex-direction: column;
        padding: 4%;
    }

    #skills-grid {
        grid-template-columns: auto;
    }
    
    .intro-title {
        font-size: 2em;
    }

    #summary > p {
        font-size: 1em;
    
        margin-bottom: 1%;
    }

    #skills-grid > div h2 {
        font-size: 1.5em;
        margin-top: 1%;
        margin-bottom: 1%;
    }
    
    #skills-grid > div p {
        font-size: 1em;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    #home-page-upper-grid > div {
        margin: 4%;
        padding: 4%;
    }
}

@media (max-width: $mediaMaxWidth) and (min-width: $mediaMinWidth) {
    #page-content {
        padding-top: var(--header-height-large);
    }

    #home-page-upper-grid {
        flex-direction: column;
    }

    #skills-grid {
        grid-template-columns: auto;
    }

    .intro-title {
        font-size: 3em;
    }

    #summary > p {
        font-size: 1.5em;
    
        margin-bottom: 1.5%;
    }

    #skills-grid > div h2 {
        font-size: 2em;

        margin-top: 1.5%;
        margin-bottom: 1.5%;
    }
    
    #skills-grid > div p {
        font-size: 1.5em;

        margin-top: 1.5%;
        margin-bottom: 1.5%;
    }

    #home-page-upper-grid > div {
        margin: 4%;
        padding: 4%;
    }
}

@media (min-width: $mediaMaxWidth) {
    #page-content {
        padding-top: var(--header-height-large);
    }
    
    #home-page-upper-grid {
        flex-direction: row;
    }

    #skills-grid {
        grid-template-columns: auto auto;
    }

    .intro-title {
        font-size: 3em;
    }

    #summary > p {
        font-size: 1.5em;
    
        margin-bottom: 2%;
    }

    #skills-grid > div h2 {
        font-size: 2em;

        margin-top: 2%;
        margin-bottom: 2%;
    }
    
    #skills-grid > div p {
        font-size: 1.5em;

        margin-top: 2%;
        margin-bottom: 2%;
    }

    #home-page-upper-grid > div {
        margin: 2%;
        padding: 2%;
    }
}