$mediaMaxWidth: 1260px;
$mediaMidWidth: 960px;
$mediaMinWidth: 768px;

.games-list {
	display: grid;
	overflow-x: hidden;
}

.game-entry {
    background-position: center;
    background-size: cover;
	width: 100%;
    border: 1px solid black;

	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-start;
	align-items: flex-end;
}

.game-entry > img {
	padding: 2%;
}

.grow {
	-webkit-transition: width 500ms;
	-moz-transition: width 500ms;
	transition: width 500ms;

    cursor: pointer;
}

@media (max-width: $mediaMinWidth) {
	.games-list {
		grid-template-columns: repeat(2, 50%);
	}

	.game-entry {
		height: 200px;
	}
	
	.game-entry:hover {
		width: 150%;
	}

	.game-entry > img {
		width: 32px;
		height: 32px;
	}
}

@media (max-width: $mediaMaxWidth) and (min-width: $mediaMinWidth) {
	.games-list {
		grid-template-columns: repeat(5, 20%);
	}

	.game-entry {
		height: 200px;
	}
	
	.game-entry:hover {
		width: 120%;
	}

	.game-entry > img {
		width: 32px;
		height: 32px;
	}
}

@media (min-width: $mediaMaxWidth) {
	.games-list {
		grid-template-columns: repeat(5, 20%);
	}

	.game-entry {
		height: 200px;
	}
	
	.game-entry:hover {
		width: 120%;
	}

	.game-entry > img {
		width: 48px;
		height: 48px;
	}
}