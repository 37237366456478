$mediaMaxWidth: 1260px;
$mediaMidWidth: 960px;
$mediaMinWidth: 810px;
$mediaAbsMinWidth: 600px;

.sc-music-list {
    display: grid;
    width: 100%;
}

.music-entry {
    text-align: center;

    height: 100%;

    display: grid;
    grid-template-rows: 85% 15%;
}

.music-entry > iframe {
    border: 0px !important;
    background-color: #F1F2EF;
    height: 100%;
}

.music-entry > div {
    height: 100%;
}

.music-entry-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: #bfbfbf;
    border-top: .5px solid black;
    border-bottom: .5px solid black;
}

.music-entry-title > a {
    margin-left: 2%;
    margin-right: 2%;
}

.bandcamp-embeds {
    display: grid;
    width: 100%;
}

.bandcamp-embeds > div {
    margin: 2%;
}

.bandcamp-embeds > div > iframe {
    width: 100%;
    border-width: 0;
}

#featured-bandcamp-embeds > div {
    height: 120px;
}

#featured-bandcamp-embeds > div > iframe {
    height: 120px;
}

#favourites-bandcamp-embeds > div {
    height: 120px;
}

#favourites-bandcamp-embeds > div > iframe {
    height: 120px;
}

#acapellas-bandcamp-embeds > div {
    height: 120px;
}

#acapellas-bandcamp-embeds > div > iframe {
   height: 120px;
}

#archives-bandcamp-embeds > div {
    height: 240px;
}

#archives-bandcamp-embeds > div > iframe {
    height: 240px;
}

#featured-section {
    padding-top: 0%;
    padding-bottom: 0%;
}

#archives-section {
    padding-top: 0%;
    padding-bottom: 0%;
}

#archives-section a {
    color: #00FFFF;
}

@media (max-width: $mediaAbsMinWidth) {
    .sc-music-list {
        grid-template-columns: auto;
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .bandcamp-embeds {
        grid-template-columns: auto;
    }

    #archives-section {
        margin-bottom: 64px;
    }
}

@media (max-width: $mediaMinWidth) and (min-width: $mediaAbsMinWidth) {
    .sc-music-list {
        grid-template-columns: auto;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .bandcamp-embeds {
        grid-template-columns: auto auto;
    }

    #favourites-bandcamp-embeds {
        grid-template-columns: auto auto;
    }

    #archives-section {
        margin-bottom: 96px;
    }
}

@media (max-width: $mediaMaxWidth) and (min-width: $mediaMinWidth) {
    .sc-music-list {
        grid-template-columns: auto auto;
        padding-top: 0.75%;
        padding-bottom: 0.75%;
    }

    .bandcamp-embeds {
        grid-template-columns: auto auto auto;
    }

    #featured-bandcamp-embeds {
        grid-template-columns: auto auto;
    }

    #archives-section {
        margin-bottom: 96px;
    }
}

@media (min-width: $mediaMaxWidth) {
    .sc-music-list {
        grid-template-columns: auto auto auto auto;
        padding-top: 0.75%;
        padding-bottom: 0.75%;
    }

    .bandcamp-embeds {
        grid-template-columns: auto auto auto;
    }

    #favourites-bandcamp-embeds {
        grid-template-columns: auto auto auto auto auto;
    }

    #archives-section {
        margin-bottom: 96px;
    }
}