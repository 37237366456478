@font-face {
  font-family: 'RainyHearts';
  src: local('RainyHearts'), url("../fonts/rainyhearts.ttf") format('truetype');
}
@font-face {
  font-family: 'Upheaval';
  src: local('Upheaval'), url("../fonts/upheavtt.ttf") format('truetype');
}
@font-face {
  font-family: 'VCR_OSD_Mono';
  src: local('VCR_OSD_Mono'), url("../fonts/VCR_OSD_MONO_1.001.ttf") format('truetype');
}