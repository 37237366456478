$mediaMaxWidth: 1260px;
$mediaMidWidth: 960px;
$mediaMinWidth: 768px;
$mediaAbsMinWidth: 600px;

:root {
	--color-red: #730000;
	--color-purple: #730bec;
	--color-blue: #0000ec;
	--color-pink: #ff0bec;
  
	--color-transparent: rgba(255, 255, 255, 0.05);
  
	--color-white: #fff;
	--color-black-1: #111;
	--color-black-2: #222;
	--color-black-3: #444;
  
	--color-trans: rgba(0, 0, 0, 0.85);
  
	--speed-normal: 0.5s;
	--speed-fast: 0.8s;

	--maxScreenWidth: 1260px;
	--midScreenWidth: 960px;
	--minScreenWidth: 768px;
	--absMinScreenWidth: 600px;
  }

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

* {
	font-family: RainyHearts, Upheavel, VCR_OSD_Mono, Lato;
}

html, body {
	margin: 0;
	padding: 0;

	scroll-behavior: smooth;

	background: var(--color-black-1);
}

.section {
	border-bottom: 1px solid var(--color-transparent);
	padding-bottom: 0%;
}

.padded-section {
	padding: 2%;
}

.section-header {
	font-size: 6em;
    text-align: center;
    font-family: Upheaval;
    padding: 2%;
}

.nav-anchor {
	display: block;
    position: relative;
    visibility: hidden;
}

.padded-section > p {
	font-family: RainyHearts, Upheavel, VCR_OSD_Mono, Lato;
    font-size: 1.5em;

    color: var(--color-black-3);
    letter-spacing: 2.5px;

    margin: 0;

    text-align: center;
}

.section > p {
    font-family: RainyHearts, Upheavel, VCR_OSD_Mono, Lato;
    font-size: 1.5em;

    color: var(--color-black-3);
    letter-spacing: 2.5px;

    margin: 0;

    text-align: center;
}

@media (max-width: $mediaMinWidth) {
	.nav-anchor {
		transform: translateY(-70px);
	}

    .section > p {
        padding: 4%;
    }

	.padded-section > p {
        padding: 4%;
    }
}

@media (max-width: $mediaMaxWidth) and (min-width: $mediaMinWidth) {
	.nav-anchor {
		transform: translateY(-100px);
	}

    .section > p {
        padding: 3%;
    }

	.padded-section > p {
        padding: 3%;
    }
}

@media (min-width: $mediaMaxWidth) {
	.nav-anchor {
		transform: translateY(-100px);
	}

    .section > p {
        padding: 2%;
    }

	.padded-section > p {
        padding: 2%;
    }
}