$mediaMaxWidth: 1260px;
$mediaMidWidth: 960px;
$mediaMinWidth: 768px;

/* Links & such */
#links {
    position: fixed;
    bottom: 0;
    right: 0;
    
    display: flex;
    flex-direction: row;

    z-index: 10;
}

#links > a {
    padding: 1vw;
}

#links > a > img {
    width: 100%;
    filter: brightness(1);
    transition: all .25s ease-in-out;
}

#links > a > img:hover {
    cursor: pointer;

    filter: brightness(0);
    transition: all .15s ease-in-out;
}

.link-inactive {
    opacity: 0;

    width: 0vw;
}

.link-active {
    opacity: 1;

    transition-duration: 2s;
}


@media (min-width: $mediaMinWidth) {
    #links {
        justify-content: end;
    }

    .link-active {
        width: 64px;
    }

    #links {
        width: fit-content;
        border-radius: 24px 0px 0px 0px;
    }
}

@media (max-width: $mediaMinWidth) {
    #links {
        justify-content: space-evenly;
    }

    .link-active {
        width: 48px;
    }

    #links {
        width: 100%;
    }
}