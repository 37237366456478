.loading-text-container {
    display: grid;
    overflow-y: hidden;

    transition-duration: .5s;
    transition-delay: 1s;
}

.loading-text-container > div {
    grid-column-start: 1;
    grid-row-start: 1;
    
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-black-3);
    font-size: 2em;
    letter-spacing: 5px;
}

.loading {
    transition-duration: 1s;

    span {
        display: inline-block;

        animation: loading 2s infinite;

        @for $i from 1 through 20 {
            &:nth-child(#{$i+1}) {
                animation-delay: #{$i*.05}s;
            }
        }
    }
}

@keyframes loading {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.loading-text-container-active {
    opacity: 1;
    height: 200px;
}

.loading-text-container-inactive {
    opacity: 0;
    height: 0px;
}

.loading-text-active {
    opacity: 1;
    transform: translateY(0px);
}

.loading-text-inactive {
    opacity: 0 !important;
    transform: translateY(25px);
}