$mediaMaxWidth: 1260px;
$mediaMidWidth: 960px;
$mediaMinWidth: 768px;

#repositories-list-container {
    display: grid;
    width: 100%;
    margin-top: 2%;
}

.repositories-list-item {
    display: flex;

    justify-content: center;
    align-items: center;
}

.repo-widget {
    max-width: 100% !important;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: $mediaMinWidth) {
    #repositories-list-container {
        grid-template-columns: 100%;
    }

    .repositories-list-item:nth-child(1n)  {
        padding: 4%;
    }
}

@media (max-width: $mediaMaxWidth) and (min-width: $mediaMinWidth) {
    #repositories-list-container {
        grid-template-columns: repeat(2, 50%);
    }

    .repositories-list-item:nth-child(1n)  {
        padding-left: 2%;
        padding-bottom: 4%;
    }

    .repositories-list-item:nth-child(2n)  {
        padding-right: 2%;
        padding-bottom: 4%;
    }
}

@media (min-width: $mediaMaxWidth) {
    #repositories-list-container {
        grid-template-columns: repeat(4, 25%);
    }

    .repositories-list-item:nth-child(1n)  {
        padding-left: 4%;
        padding-bottom: 4%;
    }

    .repositories-list-item:nth-child(4n)  {
        padding-right: 4%;
        padding-bottom: 4%;
    }
}