$mediaMaxWidth: 1260px;
$mediaMidWidth: 960px;
$mediaMinWidth: 768px;

#header {
    width: 100%;

    background: var(--color-black-1);
    border-bottom: 1px solid black;

    z-index: 5;

    position: fixed;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

#navigation {
  display: grid;
  grid-template-columns: repeat(4, 25%);
}

#navigation button {
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-white);
}

.nav-button:first-child {
  margin-left: 2%;
  margin-right: 1%;
}

.nav-button:last-child {
  margin-left: 1%;
  margin-right: 2%;
}

.nav-button {
  padding: 5%;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 2%;
  margin-bottom: 2%;

  color: white;
  text-decoration: none;

  transition: var(--speed-normal);

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid transparent;
    animation: animate var(--speed-fast) steps(8) forwards;

    cursor: pointer;
  }
}

@keyframes animate {
  0% {
    background-position-y: 0;
  }
  100% {
    background-position-y: -480px;
  }
}

.nav-red {
  border: 1px solid var(--color-red);
  &:hover {
    background: var(--color-red) url("../images/pixel-button-backgrounds/pixel-bg-red.png");
  }
}

.nav-blue {
  border: 1px solid var(--color-blue);
  &:hover {
    background: var(--color-blue) url("../images/pixel-button-backgrounds/pixel-bg-blue.png");
  }
}

.nav-purple {
  border: 1px solid var(--color-purple);
  &:hover {
    background: var(--color-purple) url("../images/pixel-button-backgrounds/pixel-bg-purple.png");
  }
}

.nav-pink {
  border: 1px solid var(--color-pink);
    &:hover {
      background: var(--color-pink) url("../images/pixel-button-backgrounds/pixel-bg-pink.png");
    }
}

@media (min-width: $mediaMinWidth) {
  .nav-button {  
    letter-spacing: 2px;

    font-size: 1.5em;
  }

  #navigation button {
    padding: 2%;
  }
  
  #navigation button:nth-child(1n)  {
    margin-left: 4%;
    margin-top: 4%;
    margin-bottom: 4%;
  }
  
  #navigation button:nth-child(4n)  {
    margin-right: 4%;
    margin-top: 4%;
    margin-bottom: 4%;
  }
}

@media (max-width: $mediaMinWidth) {
  .nav-button {
    letter-spacing: 0px;

    font-size: 1em;
  }

  #navigation button {
    padding: 4%;
  }
  
  #navigation button:nth-child(1n)  {
    margin-left: 8%;
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  #navigation button:nth-child(4n)  {
    margin-right: 8%;
    margin-top: 8%;
    margin-bottom: 8%;
  }
}